import axios from 'axios'
import * as R from 'ramda'

export const submitForm = async (action, data) => {
  const formData = new FormData()

  formData.append(window.csrfTokenName, window.csrfToken)
  formData.append('action', action)

  if (data) {
    R.map(item => {
      formData.append(item.name, item.value)
    }, data)
  }

  return axios({
    method: 'post',
    url: '/',
    data: formData,
    config: { headers: {'Content-Type': 'multipart/form-data' }}
  })
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
